import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";

function EntregarNota() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS_VENTAS_TIENDAS = process.env.REACT_APP_URL_ARTICULOS_VENTAS_TIENDAS
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS

  const [articulos, setArticulos] = useState([]);
  const [selectedNota, setSelectedNota] = useState("");
  const [total_cantidad_nueva, setTotalCantidadNueva] = useState(0);

  const [validaBoton, setValidaBoton] = useState(true);
  const [botonGuardar, setBotonGuardar] = useState(false);

  useMemo(()=>{

    if(selectedNota.length == 24){
    // setSelectedNota(e);
    axios
      .get(`${URL_ARTICULOS_VENTAS_TIENDAS}Nota/${selectedNota}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        if( user.areas == allArticulos[0].ventasTiendas[0].areas[0]._id ){
        if(allArticulos[0].ventasTiendas[0].entregado == "No"){
        let arrayArticulos = allArticulos.map((a) => {
          return {
            codigo: a.articulos[0].SKU,
            nombre: a.articulos[0].nombre,
            piezasPorCaja: a.articulos[0].piezasPorCaja,
            cantidad: a.cantidad,
            cajas: (
              parseFloat(a.cantidad) / parseFloat(a.articulos[0].piezasPorCaja)
            ).toFixed(2),
          };
        });
        setArticulos(arrayArticulos);

        let cant = allArticulos.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidadNueva(TC)
        setBotonGuardar(true)

      } else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "La Nota ya fue entregada",
        });
        setSelectedNota("")
        }
      } else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "La nota No pertenece a tu tienda",
        });
        setSelectedNota("")
        }
      })
      .catch((err) => {
        console.log(err);
      })}
  },[selectedNota])

//  async function BuscaNota(e) {
//     setSelectedNota(e);
//    await axios
//       .get(`${URL_ARTICULOS_VENTAS_TIENDAS}Nota/${e}`, {
//         headers: {
//           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//         },
//       })
//       .then((response) => {
//         let allArticulos = response.data;
//         if( user.areas == allArticulos[0].ventasTiendas[0].areas[0]._id ){
//         if(allArticulos[0].ventasTiendas[0].entregado == "No"){
//         let arrayArticulos = allArticulos.map((a) => {
//           return {
//             codigo: a.articulos[0].SKU,
//             nombre: a.articulos[0].nombre,
//             piezasPorCaja: a.articulos[0].piezasPorCaja,
//             cantidad: a.cantidad,
//             cajas: (
//               parseFloat(a.cantidad) / parseFloat(a.articulos[0].piezasPorCaja)
//             ).toFixed(2),
//           };
//         });
//         setArticulos(arrayArticulos);

//         let cant = allArticulos.map((c) => parseFloat(c.cantidad));
//         let TC = cant.reduce((t, total, index) => t + total, 0);
//         setTotalCantidadNueva(TC)
//         setBotonGuardar(true)

//       } else{
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "La Nota ya fue entregada",
//         });
//         setSelectedNota("")
//         }
//       } else{
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "La nota No pertenece a tu tienda",
//         });
//         setSelectedNota("")
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }

  function guardaEntregado(){
    axios
    .patch(`${URL_VENTAS_TIENDAS}/${selectedNota}`,
    {
      entregado: "Si",
    },
     {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then(()=>{
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Se Entrego",
        showConfirmButton: false,
        timer: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Piezas por Caja", field: "piezasPorCaja", sortable: true },
    { name: "Cajas", field: "cajas", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
  ];

  let totalCajas =0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.entregarNota ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>

          <h3 align="center">Entrega de Mercancia</h3>
          <div className="row">
            <div className="col-md-2">
              <Label>Nota</Label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <Input
                bsSize="sm"
                type="text"
                value={selectedNota}
                onChange={(e) => {
                  setSelectedNota(e.target.value);
                }}
              ></Input>
            </div>
          </div>
          <br />

          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Codigo</th>
              <th>Nombre</th>
              <th>Pzas Caja</th>
              <th>Cajas</th>
              <th>Cantidad</th>
            </tr>

            <tbody>
              {articulos.map((ao) => {
                totalCajas = totalCajas + parseFloat(ao.cajas)
                return (
                  <tr key={ao._id}>
                    <td>{ao.codigo}</td>
                    <td>{ao.nombre}</td>
                    <td>{ao.piezasPorCaja}</td>
                    <td>{ao.cajas}</td>
                    <td>{ao.cantidad}</td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td td className="negrita" align="center">
                  Total
                </td>
                <td td className="negrita">
                  {totalCajas.toFixed(2)}
                </td>
                <td td className="negrita">
                  {total_cantidad_nueva}
                </td>
              </tr>
            </tbody>
            <br/>
          {validaBoton  && botonGuardar ? (
              <Button type="submit" className="btn btn-success" tabindex="-1" onClick={e=>{guardaEntregado()}}>
                Entregar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" tabindex="-1" disabled>
                Entregar
              </Button>
            )}
          </Table>
        </div>
      ) : undefined}
    </>
  );
}

export default EntregarNota;
