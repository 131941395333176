import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';

function ListadoSurtidos() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-90);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [surtidos, setSurtidos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [autorizadoSurtido, setAutorizadoSurtido] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  const [modalEntregar, setModalEntregar] = useState(false);
  const toggleEntregar = () => setModalEntregar(!modalEntregar);
  const [paqueterias, setPaqueterias] = useState([]);
  const [selectedPaqueteria, setSelectedPaqueteria] = useState("");
  const [surtidosPaqueteria, setSurtidosPaqueteria] = useState([]);
  const [idsArticulo, setIdsArticulo] = useState([]);
  const [surtidosPaqueteriaGuarda, setSurtidosPaqueteriaGuarda] = useState([]);
  const [articulos, setArticulos] = useState([]);


  const [rollos, setRollos] = useState([]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  // const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      _id: "",
      articulo: "",
      cliente: "",
    },
  ]);

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_PAQUETERIAS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allPaqueterias = response.data;
          setPaqueterias(allPaqueterias);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  useMemo(()=>{
    axios
    .get(`${URL_SURTIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              pedidos: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              clientes: a.pedidos[0].clientes[0].nombre_comercial,
              codigoClientes: a.pedidos[0].clientes[0].codigo,
              idClientes: a.pedidos[0].clientes[0]._id,
              observaciones: a.observaciones,
              numero: a.idSurtido,
              comisionTDC: a.pedidos[0].comisionTDC,
              comisionFlete: a.pedidos[0].comisionFlete,
              impuestos: a.pedidos[0].impuestos,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              idDirEnt: a.pedidos[0].sucursales,
              alias: a.pedidos[0].sucursales[0].alias,
              calle: a.pedidos[0].sucursales[0].calle,
              numero_ext: a.pedidos[0].sucursales[0].numero_ext,
              numero_int: a.pedidos[0].sucursales[0].numero_int,
              pais: a.pedidos[0].sucursales[0].pais,
              estado: a.pedidos[0].sucursales[0].estado,
              ciudad: a.pedidos[0].sucursales[0].ciudad,
              delegacion: a.pedidos[0].sucursales[0].delegacion,
              colonia: a.pedidos[0].sucursales[0].colonia,
              cp: a.pedidos[0].sucursales[0].cp,
              paqueteria: a.paqueterias[0].name,
              idPaqueteria: a.paqueterias[0]._id,
              RFC: a.pedidos[0].sucursales[0].RFC,
              area: a.pedidos[0].areas[0].name,
              vendedor:
                a.pedidos[0].colaboradores[0].nombre +
                " " +
                a.pedidos[0].colaboradores[0].apellido,
              is_active: a.is_active,
              telefono: a.pedidos[0].clientes[0].telefono,
              autorizadoSurtido: a.autorizadoSurtido
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setSurtidos(allSurtidos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio ,selectedFechaFin])

  const ListaSurtidos = comments.sort((a, b) =>
    a.idSurtido > b.idSurtido ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.numero, a.fecha, a.clientes, a.paqueteria, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Paqueteria", "Pedido", "Surtio"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    doc.save(`Surtidos.pdf`);
  }

  function excel() {
    const dataExcel = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Pedido: a.pedidos,
          Surtio: a.user,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNotas",
        sheetFilter: ["Numero", "Fecha", "Cliente", "Pedido", "Surtio"],
        sheetHeader: ["Numero", "Fecha", "Cliente", "Pedido", "Surtio"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.numero, a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Pedido", "Surtio"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Notas",
          email: mailTo,
          fileName: "ListadoNotas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    alias,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    vendedor,
    telefono,
    autorizadoSurtido
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos:
                a.articulos[0].SKU +
                " " +
                a.articulos[0].nombre,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.precio,
              precioOriginal: a.articulos[0].venta,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              cliente: a.articulosPedido[0].cliente,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        // let agrupado = dataFinal.reduce(function (groups, item) {
        //   const val = item["idArticulo"];
        //   groups[val] = groups[val] || {
        //     idArticulo: item.idArticulo,
        //     cantidad: 0,
        //     cajas: 0,
        //   };
        //   groups[val].cantidad += item.cantidad;
        //   groups[val].cajas += item.cajas;
        //   groups[val].articulos = item.articulos;
        //   groups[val].precio = item.precio;
        //   groups[val].precioOriginal = item.precioOriginal;
        //   return groups;
        // }, []);

        // let agrupadoValues = Object.values(agrupado);

        let totalMts = dataFinal.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let totalParcial = 0;

        const data = dataFinal.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            a.cliente,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.precioOriginal),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 20, 5);

        doc.setFontSize(12);
        doc.text(`Fecha:`, 35, 15);
        doc.text(`${fecha}`, 60, 15);
        doc.text(`Nota:`, 35, 20);
        doc.text(`${numero}`, 60, 20);
        doc.text(`Pedido:`, 35, 25);
        doc.text(`${pedidos}`, 60, 25);

        doc.text(`Ejecutivo:`, 90, 15);
        doc.text(`${vendedor}`, 115, 15);
        doc.text(`Almacen:`, 90, 20);
        doc.text(`${area}`, 115, 20);
        doc.text(`Paqueteria:`, 90, 25);
        doc.text(`${paqueteria}`, 115, 25);

        if(autorizadoSurtido == "Si"){
          doc.text(`AUTORIZADO`, 150, 40);
        }

        doc.setFontSize(8);
        doc.text(`Cliente: ${codigoClientes} - ${clientes}`, 10, 32);
        // doc.text(`RFC: ${RFC}`, 150, 32);
        doc.text(`Sucursal: ${alias}`, 10, 37);
        doc.text(
          `Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int} Telefono: ${telefono}`,
          10,
          42
        );
        // doc.text(
        //   `Edo: ${estado}, Ciudad: ${ciudad}, Del: ${delegacion}, Col: ${colonia}, CP: ${cp} Paqueteria: ${paqueteria}`,
        //   10,
        //   47
        // );

        doc.autoTable({
          head: [["Articulo", "Cajas", "Cantidad", "Cliente", "Precio Original", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
            // overflow: "linebreak",
            // columnWidth: "wrap",
            // // font: 'arial',
            // overflowColumns: "linebreak",
          },
          margin: { left: 5, right: 5 },
          startY: 50,
          footStyles: {
            fontSize: 9,
            fillColor: [255,255,255],
            textColor: [0,0,0]
          },
          foot: [
            [
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              new Intl.NumberFormat("en-US").format(TM) + " Piezas",
              "",
              "",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(subTotal.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "",
              `Comision`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(iva.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(comisionTDC.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "Comision Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(comisionFlete.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(total_general.toFixed(2)),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.save(`Nota-${numero}.pdf`);
        //   }
        // });
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function excelFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    alias,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos: a.articulos[0].SKU + " " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          return groups;
        }, []);
        let agrupadoValues = Object.values(agrupado);
        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        let totalParcial = 0;
        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return {
            Articulos: a.articulos,
            Cantidad: a.cantidad,
            Precio: a.precio,
            TotalParcial: totalParcial,
          };
        });
        const dataExportExcel = Object.values(data);
        const dataExcelLimpia = dataExportExcel.filter(function (el) {
          return el != null;
        });

        const data2 = [
          {
            Cliente: codigoClientes + " " + clientes,
            Nota: numero,
            RFC: RFC,
            Direccion:
              calle +
              " " +
              numero_int +
              " " +
              numero_ext +
              " " +
              estado +
              " " +
              ciudad +
              " " +
              delegacion +
              " " +
              colonia +
              " " +
              cp,
            Impuestos: impuestos,
            IVA: iva,
            TotalGeneral: total_general,
            ComisionTDC: comisionTDC,
            ComisionFlete: comisionFlete,
            Fecha: fecha,
            Observaciones: observaciones,
          },
        ];

        var option = {};
        option.fileName = `Nota ${numero}`;
        option.datas = [
          {
            sheetData: data2,
            sheetName: `Datos Generales`,
            sheetFilter: [
              "Nota",
              "Fecha",
              "Cliente",
              "RFC",
              "Direccion",
              "Impuestos",
              "IVA",
              "TotalGeneral",
              "ComisionTDC",
              "ComisionFlete",
              "Observaciones",
            ],
            sheetHeader: [
              "Nota",
              "Fecha",
              "Cliente",
              "RFC",
              "Direccion",
              "Impuestos",
              "IVA",
              "TotalGeneral",
              "ComisionTDC",
              "ComisionFlete",
              "Observaciones",
            ],
          },
          {
            sheetData: dataExcelLimpia,
            sheetName: `Articulos`,
            sheetFilter: ["Articulos", "Cantidad", "Precio", "TotalParcial"],
            sheetHeader: ["Articulos", "Cantidad", "Precio", "TotalParcial"],
          },
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Paqueteria", field: "paqueteria", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Pedido", field: "pedidos", sortable: true },
    { name: "Surtió", field: "user", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.paqueteria.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (autorizadoSurtido) {
      computedComments = computedComments.filter((e) =>
        e.autorizadoSurtido.includes(autorizadoSurtido)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
    autorizadoSurtido
  ]);

  function autorizar(id) {
    const URL_AUTORIZADO = `${URL_SURTIDOS}/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se guardara!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizadoSurtido: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(()=>{
            Swal.fire("Good job!", "Autorizado", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }).catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          })
      }
    });
  }

  function QRGen(id, cliente, numero, paqueteria) {

    axios
    .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allRollos = response.data;
      setRollos(allRollos);

      const doc = new jsPDF("landscape", "mm", [55, 40])

      allRollos
      .map((a) => {
        let codigo = a._id;
        var base64 = qrcode.toDataURL(codigo);

        var img3 = new Image();
        img3.src = base64;
        doc.addImage(img3, "png", 13, 14, 25, 25);
        doc.setFontSize(8);
        doc.text(`Cliente: ${cliente}`, 3, 7.5);
        doc.text(`Articulo: ${a.articulos[0].SKU}`, 3, 11);
        doc.text(`Paqueteria: ${paqueteria}`, 3, 14.5);
        doc.addPage();
      });
      doc.save(`QR.pdf`);
    })
    .catch((err) => {
      console.log(err);
    });
}

  function entregar(){
    setInputFields([
      {
        id: uuidv4(),
        _id: "",
        articulo: "",
        cliente: "",
      },
    ]);

      toggleEntregar();
  
  }
  function jalaPaqueteria(id) {
    // Set the selected paqueteria
    setSelectedPaqueteria(id);

    axios
        .get(`${URL_ARTICULOS_SURTIDOS}entregarPaqueteria/${id}`, {
            headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
        })
        .then((response) => {
            let allArticulos = response.data;
            setArticulos(allArticulos);

            // Obtener los _id de cada paquetería
            let result = allArticulos.map(({ _id }) => _id);
            setIdsArticulo(result); // Guardar los ids obtenidos
        })
        .catch((err) => {
            console.log(err);
        });
}

function BuscaArticulo(id, event) {
  if (event.target.value.length >= 24) {
    if (idsArticulo.includes(event.target.value)) {
      if (
        inputFields.filter((e) => e._id == event.target.value)
          .length > 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El articulo ya fue escaneado!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i._id = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      } else {
        articulos.map((a) => {
          if (a._id == event.target.value) {
            let _id = a._id;
            let articulo = a.articulos[0].SKU + " " + a.articulos[0].nombre;
            let cliente = a.clientes[0].nombre_comercial;
            handleChangeInputSurtido(
              id,
              _id,
              articulo,
              cliente
            );
          }
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El surtido no pertenece a la paqueteria!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i._id = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  } else {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i._id = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  }
}

const handleChangeInputSurtido = (
  id,
  _id,
  articulo,
  cliente
) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i._id = _id;
      i.articulo = articulo;
      i.cliente = cliente;
    }
    return i;
  });
  setInputFields(newInputFields);
  handleAddFields();
};

const handleAddFields = () => {
  setInputFields([
    ...inputFields,
    {
      id: uuidv4(),
      _id: "",
      articulo: "",
      cliente: "",
    },
  ]);
};

const saveEntregar = async () => {
  // event.preventDefault();
  // setValidaBoton(false);
  // toggleProgreso();
  let totalPedido = inputFields.length

  try {
        inputFields.map((a) => {
          if(a._id != ""){
          axios.patch(
            `${URL_ARTICULOS_SURTIDOS}/${a._id}`,
            {
              entregado: "Si"
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(()=>{
            totalPedido = totalPedido -1
            if(totalPedido == 0 ){
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
            }
          })
        }else{ 
          totalPedido = totalPedido -1
          if(totalPedido == 0 ){
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
          }
        }
        });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: `${error.response.data}`,
    });
    console.log(error);
  }
};

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_surtidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={(e) => entregar()}

              >
                 Entregar <i class="far fa-paper-plane"></i>

              
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={
                    e=>{
                      axios
                      .get(`${URL_ARTICULOS_SURTIDOS}Desarrollo`, {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                        },
                      })
                      .then((response) => {
                        alert(response)
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                    }
                  }
                >
                  Desarrollo <i class="far fa-file-pdf"></i>
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Notas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Notas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
                  <Label>Autorizado</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={autorizadoSurtido}
                    required
                    onChange={(e) => {
                      setAutorizadoSurtido(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                if (a.is_active == "No"){
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.paqueteria}</td>
                      <td>{a.area}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.pedidos}</td>
                      <td>{a.user}</td>
                        <td>CANCELADA
                          <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.alias,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes,
                                  a.vendedor,
                                  a.telefono
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button></td>
                    </tr>
                  );
                }else{
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.paqueteria}</td>
                      <td>{a.area}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.pedidos}</td>
                      <td>{a.user}</td>
                        <>
                          <td>
                          <Button
                        size="sm"
                        className="btn"
                        color="success"
                        onClick={(e) =>
                          QRGen(
                            a._id,
                            a.clientes,
                            a.numero,
                            a.paqueteria
                          )
                        }
                      >
                        <i class="fas fa-qrcode"></i>
                      </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.alias,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes,
                                  a.vendedor,
                                  a.telefono,
                                  a.autorizadoSurtido
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="primary"
                              onClick={(e) =>
                                excelFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.alias,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes
                                )
                              }
                            >
                              <i class="far fa-file-excel"></i>
                            </Button>
                            {user.ventas_autorizar && a.autorizadoSurtido == "No" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        // id="Baja"
                                        onClick={(e) =>
                                          autorizar(a._id)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-thumbs-up"></i>
                                      </Button>
                                    </span>
                                  ) : undefined}
                          </td>
                        </>
                    </tr>
                  );
                }

              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xxl" isOpen={modalEntregar} toggle={toggleEntregar}>
        <ModalHeader toggle={toggleEntregar}>
          <h4>Entregar Guias</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Paqueteria</Label>
              <Input
                className="col-sm-12"
                bsSize="sm"
                type="select"
                value={selectedPaqueteria}
                required
                onChange={(e) => {
                  jalaPaqueteria(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {paqueterias
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            
          </Row>
          <br />

                   {/* Tabla Surtidos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Codigo</Label>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Surtido</Label>
              </Col>
              <Col md={5}>
                <Label className="mr-sm-2">Cliente</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="_id"
                      type="string"
                      value={inputField._id}
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="articulo"
                      type="string"
                      value={inputField.articulo}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={5}>
                    <Input
                      bsSize="sm"
                      name="cliente"
                      type="string"
                      value={inputField.cliente}
                      disabled
                    ></Input>
                  </Col>

                  {/* <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col> */}
                </Row>
              </div>
            ))}



          <Button
            size="sm"
            color="success"
            onClick={(e) => saveEntregar()}
            tabindex="-1"
          >
            Guardar
          </Button>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoSurtidos;
